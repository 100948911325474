
/* Theme Colors */
.has-red-background-color {
    background-color: $red;
}

.has-orange-background-color {
    background-color: $orange;
}

.has-dark-background-color {
    background-color: $dark;
}

.has-light-background-color {
    background-color: $light;
}

.has-green-background-color {
    background-color: $green;
}

.has-aqua-background-color {
    background-color: $aqua;
}

.has-teal-background-color {
    background-color: $teal;
}

.has-blue-background-color {
    background-color: $blue;
}

.has-cyan-background-color {
    background-color: $cyan;
}

.has-sand-background-color {
    background-color: $sand;
}

.has-black-background-color {
    background-color: $black;
}

.has-white-background-color {
    background-color: $white;
}

.has-gray-background-color {
    background-color: $gray-700;
}

.has-yellow-background-color {
    background-color: $yellow;
}

.has-sunshine-background-color {
    background-color: $sunshine;
}


.has-red-color {
    color: $red;
}

.has-orange-color {
    color: $orange;
}

.has-tangerine-color {
    color: $tangerine;
}

.has-lime-color {
    color: $lime;
}

.has-green-color {
    color: $green;
}

.has-aqua-color {
    color: $aqua;
}

.has-teal-color {
    color: $teal;
}

.has-blue-color {
    color: $blue;
}

.has-cyan-color {
    color: $cyan;
}

.has-sand-color {
    color: $sand;
}

.has-black-color {
    color: $black;
}

.has-white-color {
    color: $white;
}

.has-gray-color {
    color: $gray-700;
}

.has-yellow-color {
    color: $yellow;
}

.has-sunshine-color {
    color: $sunshine;
}


/* Grayscale */
.has-white-background-color {
    background-color: $white !important;
}

.has-white-color {
    color: $white !important;
}

.has-lgray-background-color {
    background-color: $gray-400 !important;
}

.has-lgray-color {
    color: $gray-400 !important; 
}

.has-gray-background-color {
    background-color: $gray-600 !important;
}

.has-gray-color {
    color: $gray-600 !important; 
}

.has-dgray-background-color {
    background-color: $gray-800 !important;
}

.has-dgray-color {
    color: $gray-800 !important; 
}

.has-black-background-color {
    background-color: $black !important;
}

.has-black-color {
    color: $black !important;
}