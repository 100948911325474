.mobile-menu-animation {
    width: 0;
    z-index: 300;
    transition: all .25s linear;
    position: absolute;
    top: 0;
    left: 100%;
    min-height: 100vh;
    background-color: white;
    overflow: hidden;
    box-shadow: -50px 0 200px rgba(0, 0, 0, .5);
    display: none;
    * {
        opacity: 0;
        transition: opacity ease-in-out .75s;
    }
    &.open {
        left: calc(100% - 300px);
        right: 0;
        width: 300px;
        overflow-y: scroll !important;
        display: block;
        * {
            opacity: 1;
        }
    }
    .menu-container {
        -overflow-scrolling:touch!important;
        -webkit-overflow-scrolling:touch!important;
        display: flex;
        flex-direction: column;
        max-height: 100vh;
        min-height: -webkit-fill-available;
        padding-bottom: env(safe-area-inset-bottom);
    }
    a.mobile-nav-link {
        color: $aqua;
        text-align: left;
        font-size: 1.7em;
        display: inline-block;
        text-transform: uppercase;
        letter-spacing: 2px;
        line-height: 1.2em;
        text-shadow: 2px 2px 0 rgba(0, 0, 0, .1);
        font-family: $headings-font-family;
        font-weight: $font-weight-bold;
        width: 100%;
    }
    .dropdown-container {
        a {
            display: block;
            width: 100%;
            border: none;
            font-size: 1.4em;
            text-transform: none;
            overflow: hidden;
            color: $orange;
            font-family: $headings-font-family;
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    .social a {
        color: $aqua;
        padding-bottom: 175px;
    }

}

.nav-container {
    transition: height linear .25s;
    padding-top: 0 !important;
}

.mobile-button {
    font-size: .8em;
    line-height: 1.2em;
    background-color: $secondary;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: $white;
    flex-direction: column;
    letter-spacing: 1px;
    .fa {
        font-size: 2.6em;
    }
}


@media screen and (min-width: map-get($grid-breakpoints, lg)) {
    ul.navigation-menu {
        list-style: none;
        display: flex;
        li {
            padding: 0.3rem 1rem .5rem;
            a.nav-link {
                display: block;
                padding: .25rem 0;
                text-transform: uppercase;
                color: $gray-600;
                transition: color linear .25s;
                text-decoration: none;
                line-height: 1em;
                &:hover {
                    color: $aqua;
                }
            }
            &.btn-aqua {
                padding: 0.25rem 1.25rem .15rem;
                a {
                    color: $white;
                    &:hover {
                        border-color: transparent;
                    }
                }
            }
            &.dropdown:focus,
            &.dropdown:hover,
            &.dropdown:focus-within {
                ul.dropdown-menu {
                    display: block;
                    border-top: .5rem $aqua solid;
                    margin-top: 0 !important;
                    li {
                        padding: 0;
                    }
                    .dropdown-item:hover {
                        background-color: $aqua;
                        color: white;
                    }
                }
            }
        }
    }
}