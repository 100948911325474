.scroll-hide {
    opacity: 1;
    display: block;
    transition: opacity .5s linear;
}

.scroll-show {
    opacity: 0;
    display: none;
    transition: opacity .5s linear;
}

.scrolling {

    .scroll-hide {
        opacity: 0;
        display: none;
    }

    .scroll-show {
        opacity: 1;
        display: block;
    }
}