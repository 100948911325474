/*--------------------------------------------------------------
# WP Core Alignments
--------------------------------------------------------------*/
.alignleft {
    display: block;   
    margin: 1rem 0;
    width: 100%;
    position: relative;
    z-index: 1;

    @media (min-width: 576px){
        float: left;
        margin: .25rem 2rem .75rem 0 !important;
    }

    img {
        width: 100% !important;
        height: auto !important;
    }
}

.alignright {
    display: block;   
    margin: 1rem 0;
    width: 100%;
    position: relative;
    z-index: 1;

    @media (min-width: 576px){
        float: right;
        margin: .25rem 0 .75rem 2rem !important;
    }

    img {
        width: 100% !important;
        height: auto !important;
    }
}

.aligncenter {
    clear: both;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: .5rem;
    position: relative;
    z-index: 1;
}

.wp-block-separator:not(.is-style-wide):not(.is-style-dots) {
    width: 80px;
    height: 5px;
    margin: 1rem 0;
}

.wp-block-column {
    flex-grow: 1;
    min-width: 0;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-bottom: 28px;

    /* fix IE */
    p {
        display: block;
        max-width: 100%;
    }
}

.wp-block-columns {
    margin-bottom: 0 !important;

    @media (min-width: 782px){
        flex-wrap: wrap !important;
    }

    @media (min-width: 993px){
        flex-wrap: nowrap !important;
    }
}