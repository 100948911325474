// Critical Components for every load
@import "fonts";

// Required Bootstrap Components
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// Bootstrap Customizations
@import "variables";

// Bootstrap Components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/tables";
// @import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/dropdown";
// @import "~bootstrap/scss/custom-forms";

@media print {
    @import "~bootstrap/scss/print";
}

// Theme Files & Customizations
@import "wordpress";
@import "accessibility";
@import "structure";
@import "navigation";
@import "content";
@import "colors";
@import "scrolling";

.pgc-panel-important .pgc-important label {
    animation-duration: 4s !important;
}

@media (prefers-reduced-motion) {
    .pgc-panel-important .pgc-important label,
    .animated {
        animation-name: none !important;
        animation-duration: 0s !important;
    }
}