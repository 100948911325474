#app {
  .wp-block-button__link,
  .btn {
    font-family: "proxima-nova-extra-condensed", sans-serif;
    text-decoration: none;
    box-shadow: none;
    font-family: $headings-font-family;
    font-weight: $font-weight-bold;
    border: 3px solid #ee703d;
    color: #ee703d;
    font-size: 1.35em;

    &.btn-sm {
      padding: 0.25rem 0.75rem 0.2rem;
    }

    &.btn-lg {
      padding: 0.75rem 1rem 0.6rem;
    }

    &.btn-book {
      color: orange !important;
      border: 1px solid rgba(0, 0, 0, 0.1);

      &:hover {
        background-color: $white;
      }
    }
  }

  .bg-dteal {
    background-color: $dteal;
  }

  .lazy {
    opacity: 0 !important;
    transition: opacity linear 0.5s;

    &[lazy="loading"] {
      opacity: 0 !important;
    }

    &[lazy="loaded"],
    &[lazy="error"] {
      opacity: 1 !important;
    }
  }

  .pointer {
    cursor: pointer;
  }

  .text-underline {
    text-decoration: underline;
  }
}

h2{
  font-size: 2rem;
}

h2,
h3 {
  line-height: 0.9em !important;
  font-weight: 900;
}

.display-1,
.display-2,
.display-3 {
  font-family: $headings-font-family;
}

span.home-method {
  display: flex;
  color: rgba($black, 0.6);

  &.text-large {
    font-size: 1em;
  }

  i.fa {
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-2px);
  }
}

span.contact-method {
  display: flex;

  &.text-large {
    font-size: 1em;
  }

  i.fa {
    font-size: 1em;
    color: $orange;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-2px);
  }

  a.contact-content {
    font-weight: inherit;
    text-decoration: inherit !important;
    color: inherit !important;
  }
}

#content {
  position: relative;
  z-index: inherit;
  background-color: $white;

  @media screen and (min-width: map-get($grid-breakpoints, xl )) {
    .wp-block-column.content-max {
      max-width: 600px !important;
    }
  }

  .text-shadow {
    text-shadow: 0 0 .5rem rgba($black, 0.4);
  }

  figure.w-100 {
    img {
      width: 100%;
    }
  }

  .wp-block-columns.border-top {
    border-width: 10px !important;
  }
}

/* Trip Template Styles */

h2.catamaran {
  font-size: 2.8em;
}

.trip {
  .trip-mast {
    .intro-content {
      border-width: 5px !important;

      .trip-title {
          color: $gray-600;
      }
    }
  }
}

.page-header {
  background-size: cover;
}

.departure-location {
  min-height: 400px;
}

/*This is the css for the gradients*/
.img-gradient {
  position: absolute;
  height: 50%;
  width: 100%;
  z-index: 3;
  opacity: 0.5;
  bottom: 0;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #0c374b 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #0c374b 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    #0c374b 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

  &.fixed {
    position: fixed;
    z-index: 0;
    height: 33%;
    opacity: 0.7;
  }

  &.gradient-teal {
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, $teal 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, $teal 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      $teal 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }

  &.gradient-blue {
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, $blue 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, $blue 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      $blue 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }

  &.gradient-green {
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, $green 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, $green 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      $green 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }

  &.gradient-orange {
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, $orange 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, $orange 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      $orange 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }
}
/*close css for gradients*/

/*This is the css for the video section*/
.video-section {
  position: relative;
  z-index: -1;
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  transition: height .5s linear;
}

.scroll-button {
  position: absolute;
  z-index: 4;
  width: 100%;
  height: 50%;
  bottom: 0;
  padding-bottom: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  cursor: pointer;

  /** iPhone X viewport fix **/
  @media only screen and (min-device-width: 375px) and (max-device-height: 896px) and (orientation : portrait) {
    padding-bottom: 8rem;
  }

  @media screen and (min-width: map-get($grid-breakpoints, lg )){
    padding-bottom: 2rem;
    height: 30%;
  }

  p {
    text-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
    width: 800px;
    max-width: 96%;
    margin: 3rem auto 0;
    position: relative;
    z-index: 5;
    line-height: 1em;
    transition: color 0.25s linear;
  }

  .img-gradient {
    height: 100%;
    opacity: 0.75;
  }

  .scroll-arrow {
    margin: 0 auto;
    display: block;
    position: relative;
    z-index: 4;
    transition: margin linear 0.25s;
  }

  &:hover {
    h1 {
      color: $white;
    }
    .scroll-arrow {
      margin: 0.5rem auto 0;
    }
  }
}
/*end css for video section*/

.footer-contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

hr.wp-block-separator.is-style-wide {
  margin: 3rem 0;
  border-color: #CCCCCC;
}