// Full page Layout
html {
    height: 100%;
    width: 100%;
    overflow-x: hidden;

    * {
        text-rendering: unset;
    }

    body {
        height: 100%;
    }
}

#app {
    height: auto !important;
    min-height: 100%;
    overflow-x: initial;

    .site-wrapper {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        overflow: hidden;

        &.full-height {
            height: 100vh;
        }

        &.pop-over-open {
            height: 100vh;
        }

        &.mobile-menu-open {
            display: flex;
            // min-height: 100vh;
            width: 100%;
            position: fixed;
        }
    }

    @media (max-width: map-get($grid-breakpoints, lg )) {
        .container-md,
        .container-sm,
        .container {
            max-width: 100% !important;
        }
    }
}

#top {
    position: fixed;
    width: 100%;
    z-index: 70;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);

    .logo {
        text-decoration: none;

        img {
            height: auto;
            margin-top: -0.7rem;
            margin-bottom: 0.25rem;
            transition: height linear 0.25s, width linear 0.25s;
        }
    }
}

#mast {
    height: 60px;

    @media screen and (min-width: map-get($grid-breakpoints, md )) {
        height: 60px;
    }
}

.VideoBg {
    display: block;
    position: fixed;
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
    left: 0;
    z-index: -1;

    video {
        position: absolute;
        top: 50%;
        left: 50%;
        visibility: hidden;
        transform: translate(-50%, -50%);
        z-index: -1;
    }
}

.VideoBg__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

#mid {
    flex-grow: 1;
    position: relative;
    z-index: 2;
    padding-top: 60px;
    background-color: $white;

    &.nopad {
        padding-top: 0;
    }

    .section {
        position: relative;
        z-index: 1;
        overflow: hidden;
    }

    .section-background {
        position: absolute;
        z-index: -1;
        background-position: center;
        background-size: cover;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        &.parallax {
            height: 120%;
        }
    }

    /* Trip Cards */
    .service-card {
        border-width: 8px !important;

        .service-card-image {
            background-size: cover;
            background-position: center;
        }

        .card-body {
            padding: 1.5rem;
            font-weight: 400;
            color: black;
        }

        .features {
            font-size: 0.8em;
            font-weight: 400;
            color: #6f6f6f;

            .fa-hourglass-end {
                font-size: 1.3em;
            }

            .fa-tachometer {
                font-size: 1.4em;
            }
        }

        .card-title {
            font-family: "proxima-nova-extra-condensed", sans-serif;
            font-weight: bold;
            color: #fff;
            line-height: 1;

            @media screen and (min-width: map-get($grid-breakpoints, md )) {
                font-size: 2.8rem;
                color: #72757e;
                background-color: transparent !important;
            }

            a {
                color: inherit !important;
                padding-top: 0.25rem;
            }
        }
    }

    /** Home page Review **/
    .quoteText {
        font-size: 1.5em;
        line-height: 1.5;
    }

    .parallax {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    //change font style of lagoon pontoon link on home page
    .lagoon-pontoon a {
        text-decoration: none;
        color: black;
    }
    //--//

    //Control font size of "More Aqua Adventures" text on home page
    .adventure {
        font-size: 1.2rem;
    }
    //--//
}

#bottom {
    position: relative;
    z-index: 2;

    .logo img {
        width: 190px;
    }

    .copyright {
        font-size: 0.9em;
    }

    a {
        color: inherit;
    }
}

.grecaptcha-badge {
    z-index: 0;
}

.call-viewer {
    position: absolute;
    top: 0;
    background: rgba(255, 255, 255, 0.9);
    z-index: 999999;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
        height: 160px;
        width: 160px;
        font-size: 1.6rem;
        padding: 1rem;
        line-height: 1em;
        font-family: $headings-font-family;
    }
}
